import "./App.css";
import LeadGen from "./LeadGen";

function App() {
  return (
    <LeadGen></LeadGen>
  );
}

export default App;
