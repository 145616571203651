import React, { Component } from "react";

export default class LeadGen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedUp: false,
    };

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(event) {
    event.preventDefault();
    const action = event.target.action;
    const data = new FormData(event.target);
    fetch(action, {
      method: "POST",
      body: data,
    }).then(() => {});
    this.setState({
      signedUp: true,
    });
  }

  render() {
    return (
      <div className="App">
        <nav className="navbar navbar-light bg-light static-top">
          <div className="container">
            <img
              className="navbar-brand"
              width="240"
              height="120"
              src="img/default-monochrome.svg"
            />
          </div>
        </nav>

        <header className="masthead">
          <div className="container position-relative">
            <div className="row justify-content-center">
              <div className="col-xl-6">
                <div className="text-center text-white">
                  <h1 className="mb-5">
                    Own and operate a brewery? Need to expand production?
                    Sign-up for early access!
                  </h1>

                  {!this.state.signedUp && (
                    <form
                      method="POST"
                      action="https://script.google.com/macros/s/AKfycbzfVw8p_DLuR576BIzCjOwYygP3KMpk1ewV3FFsO6lhI2uOi2Dgnd780mY1bpvDy0pa7w/exec"
                      onSubmit={this.submitForm}
                    >
                      <div className="row">
                        <div className="col">
                          <input
                            className="form-control form-control-lg"
                            name="Email"
                            type="email"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                  {this.state.signedUp &&
                    "Thanks for signing up and stay tuned from updates - we'll reach out shortly to provide more information!"}
                </div>
                {}
              </div>
            </div>
          </div>
        </header>
        <section className="features-icons bg-light text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="bi-window m-auto text-primary"></i>
                  </div>
                  <h3>Streamline Operations</h3>
                  <p className="lead mb-0">
                    Quickly adapt to market demands, enhance production
                    efficiency, and focus on core strengths!
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="bi-cash-stack m-auto text-primary"></i>
                  </div>
                  <h3>Unlock Revenue Streams</h3>
                  <p className="lead mb-0">
                    Expand your customer base and transform unused capacity into
                    profit
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="bi-cup-straw m-auto text-primary"></i>
                  </div>
                  <h3>Make Great Beer</h3>
                  <p className="lead mb-0">
                    Maintain your commitment to crafting exceptional beer while
                    collaborating with other breweries!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="showcase">
          <div className="container-fluid p-0">
            <div className="row g-0">
              <div
                className="col-lg-6 order-lg-2 text-white showcase-img"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/img/barrels.jpg`,
                }}
              ></div>
              <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Revenue Boosting Opportunities</h2>
                <p className="lead mb-0">
                  <strong>Access to a Wider Customer Base:</strong> Partnering
                  with a diverse range of breweries through our marketplace
                  exposes your brand to a broader audience, potentially leading
                  to increased customer acquisition and brand recognition.
                </p>
                <p className="lead mb-0">
                  <strong>Efficient Capacity Utilization:</strong> Optimize your
                  brewing capacity by offering excess capacity to other
                  breweries during downtimes, turning unused resources into
                  revenue-generating opportunities.
                </p>
                <p className="lead mb-0">
                  <strong>Collaborative Brews:</strong> Collaborate with other
                  breweries to create unique, limited-edition brews that can
                  attract a premium price point and entice customers seeking
                  novel and exclusive experiences.
                </p>
              </div>
            </div>
            <div className="row g-0">
              <div
                className="col-lg-6 text-white showcase-img"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/img/bg-showcase-2.jpg`,
                }}
              ></div>
              <div className="col-lg-6 my-auto showcase-text">
                <h2>Operational Speed and Flexibility</h2>
                <p className="lead mb-0">
                  <strong>Rapid Scaling:</strong> With contract brewing, you can
                  quickly respond to demand spikes without incurring the time
                  and costs of expanding your own facility, enabling you to
                  capture market opportunities efficiently.
                </p>
                <p className="lead mb-0">
                  <strong>Reduced Time-to-Market:</strong> Experiment with new
                  recipes and concepts without disrupting your core operations.
                  Rapidly bring new products to market and gauge their success
                  before committing to larger production runs.
                </p>
                <p className="lead mb-0">
                  <strong>Eliminate Bottlenecks:</strong> Address production
                  bottlenecks by outsourcing specific stages of brewing to
                  specialized partners, ensuring a consistent supply even during
                  periods of high demand.
                </p>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-lg-6 my-auto showcase-text">
                <h2>Enhanced Efficiency and Resource Management</h2>
                <p className="lead mb-0">
                  <strong>Cost-effective Production:</strong> Minimize capital
                  investments in equipment and infrastructure by utilizing
                  existing facilities. Allocate your resources more
                  strategically and avoid unnecessary overhead costs.
                </p>
                <p className="lead mb-0">
                  <strong>Focus on Core Competencies:</strong> Streamline your
                  operations by delegating certain production stages to expert
                  partner breweries, allowing you to concentrate on perfecting
                  your core recipes and brand identity.
                </p>
                <p className="lead mb-0">
                  <strong>Data-Driven Insights:</strong> Leverage data analytics
                  from our platform to make informed decisions about production
                  scheduling, inventory management, and customer preferences,
                  enhancing overall business efficiency.
                </p>
              </div>
              <div
                className="col-lg-6 text-white showcase-img"
                style={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/img/bg-showcase-3.jpg`,
                }}
              ></div>
            </div>
          </div>
        </section>
        <section className="call-to-action text-white text-center" id="signup">
          <div className="container position-relative">
            <div className="row justify-content-center">
              <div className="col-xl-6">
                <h2 className="mb-4">Ready to get started? Sign up now!</h2>
                {!this.state.signedUp && (
                  <form id="contact-form">
                    <div className="row">
                      <div className="col">
                        <input
                          className="form-control form-control-lg"
                          name="Email"
                          type="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-primary btn-lg"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}

                {this.state.signedUp &&
                  "Thanks for signing up and stay tuned from updates - we'll reach out shortly to provide more information!"}
              </div>
            </div>
          </div>
        </section>
        <footer className="footer bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 h-100 text-center text-lg-start my-auto">
                <ul className="list-inline mb-2">
                  <li className="list-inline-item">
                    <a href="#!">About</a>
                  </li>
                  <li className="list-inline-item">⋅</li>
                  <li className="list-inline-item">
                    <a href="#!">Contact</a>
                  </li>
                  <li className="list-inline-item">⋅</li>
                  <li className="list-inline-item">
                    <a href="#!">Terms of Use</a>
                  </li>
                  <li className="list-inline-item">⋅</li>
                  <li className="list-inline-item">
                    <a href="#!">Privacy Policy</a>
                  </li>
                </ul>
                <p className="text-muted small mb-4 mb-lg-0">
                  &copy; Fermentable 2023. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
      </div>
    );
  }
}
